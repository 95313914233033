<template>
  <backoffice-container>
    <b-card>
      <b-tabs class="mt-2" :no-nav-style="true">
        <b-tab
          title-link-class="pb-2 mt-n1 font-weight-bolder mb-2"
          active
          @click="type = 'communityMembers'"
        >
          <template #title>
            <div class="align-items-center d-flex">
              <h4 class="m-0" :class="type === 'platformMembers' || !isMain ? 'text-dark' : 'text-primary pb-1'" :style="type === 'communityMembers' && isMain ? 'border-bottom: solid 3px' : ''">
                {{ $t('members.title') }}
              </h4>
            </div>
          </template>
          <members-component 
            v-if="type === 'communityMembers'" 
            :type="type" 
          />
        </b-tab>
        <b-tab
          v-if="isMain"
          title-link-class=" pb-2 mt-n1 font-weight-bolder mb-2"
          @click="type = 'platformMembers'"
        >
          <template #title>
            <div class="align-items-center d-flex">
              <h4 class="m-0" :class="type === 'communityMembers' ? 'text-dark' : 'text-primary pb-1'" :style="type === 'platformMembers' ? 'border-bottom: solid 3px' : ''">
                {{ $t('members.all-platform-members') }}
              </h4>
            </div>
          </template>
          <members-component 
            v-if="type === 'platformMembers'" 
            :type="type" 
          />
        </b-tab>
      </b-tabs>
    </b-card>
  </backoffice-container>
</template>

<script>
import CardWidgetMixin from '@core/mixins/widgets/CardWidgetMixin';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
//import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import BackofficeContainer from '@/backoffice/components/BackofficeContainer.vue';
//import { validatorEmailValidator } from '@/@core/utils/validations/validators';
import Ripple from 'vue-ripple-directive';
//import Placeholder from "@/assets/images/placeholders/light/general-placeholder.svg";
import MembersComponent from './components/MembersContent.vue';

export default {
  name: 'BackofficeMembersList',
  components: {
    MembersComponent,
    BackofficeContainer,
  },
  mixins: [CardWidgetMixin, ToastNotificationsMixin],
  directives: {
    Ripple,
  },
  props: {
    perPage: {
      type: Number,
      default: 15,
    },
    organizationKey: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      type: 'communityMembers',
    };
  },
  computed: {
    isMain() {
      return Object.values(this.$store.getters.mainCollective).length === 0;
    },
  },
};
</script>
